import { render, staticRenderFns } from "./InstructionButton.vue?vue&type=template&id=27e29d22&scoped=true"
import script from "./InstructionButton.vue?vue&type=script&lang=js"
export * from "./InstructionButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27e29d22",
  null
  
)

export default component.exports
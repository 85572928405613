export const ROLES =  {
  CONTRAGENT: 'CONTRAGENT',
  REVIEWER: 'REVIEWER',
  FIRST_REVIEWER: 'FIRST_REVIEWER',
  LAWYER: 'LAWYER',
  SECOND_REVIEWER: 'SECOND_REVIEWER',
  APPROVER: 'APPROVER',
  SPECTATOR: 'SPECTATOR',
  GENERAL_REVIEWER: 'GENERAL_REVIEWER',
  USER: 'USER',
  IN_PROCESS_USER: 'IN_PROCESS_USER',
  REJECTED_USER: 'REJECTED_USER',
  GUEST: 'GUEST',
};

export default {
  ROLES,
}

export const STATUSES =  {
  // Статусы для заявки для контрагента
  ON_APPROVAL: {
    value: 'ON_APPROVAL',
    text: 'На согласовании',
  },
  PKO_NOT_PASSED: {
    value: 'PKO_NOT_PASSED',
    text: 'Не пройдено',
  },
  PKO_PASSED: {
    value: 'PKO_PASSED',
    text: 'Пройдено',
  },
  PREPARE: {
    value: 'PREPARE',
    text: 'Подготовка',
  },
  ON_REWORK: {
    value: 'ON_REWORK',
    text: 'На доработке',
  },

  // Статусы для заявки для проверяющего
  NO: {
    value: 'NO',
    text: 'Отклонено',
  },
  REWORK: {
    value: 'REWORK',
    text: 'Доработка',
  },
  YES: {
    value: 'YES',
    text: 'Согласовано',
  },
  WAIT_FOR_DECISION: {
    value: 'WAIT_FOR_DECISION',
    text: 'Ожидает решения',
  },
  WAIT_IN_LINE: {
    value: 'WAIT_IN_LINE',
    text: 'Ожидает отправки',
  },

  // Статусы для реестра
  NOT_PASSED: {
    value: 'NOT_PASSED',
    text: 'Не пройдено',
  },
  PASSED: {
    value: 'PASSED',
    text: 'Пройдено',
  },
  ON_APPLICATION_APPROVING: {
    value: 'ON_APPLICATION_APPROVING',
    text: 'В стадии прохождения',
  },
  PASSED_WITH_APPLICATION_APPROVING: {
    value: 'PASSED_WITH_APPLICATION_APPROVING',
    text: 'Пройдено. В стадии прохождения',
  },
  NOT_PASSED_WITH_APPLICATION_APPROVING: {
    value: 'NOT_PASSED_WITH_APPLICATION_APPROVING',
    text: 'Не пройдено. В стадии прохождения',
  },
};

// За сколько дней до истечения срока действия ПКО подсвечивать анкету в реестре
export const BEFORE_EXPIRED_PKO_DAYS = 45;

export default {
  STATUSES,
  BEFORE_EXPIRED_PKO_DAYS,
}
